import React, { FC, createContext, CSSProperties, MouseEventHandler, useContext, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

export type GroupProps = {
   spaced?: boolean
   className?: string
}

type bsStyle =
   | 'default'
   | 'success'
   | 'gray'
   | 'primary'
   | 'warning'
   | 'danger'
   | 'outline-primary'
   | 'outline-success'
   | 'none'

export type ButtonProps = {
   type?: 'button' | 'submit' | 'reset'
   bsStyle?: bsStyle
   onClick?: MouseEventHandler<HTMLButtonElement>
   className?: string
   buttonStyle?: CSSProperties
   isLoading?: boolean
   hasAnimation?: boolean
   disabled?: boolean
   showArrow?: boolean
}

export interface ButtonComposition {
   Group: FC<PropsWithChildren<GroupProps>>
}

const ButtonGroupContext = createContext<{ spaced?: boolean }>({})

const Group: FC<PropsWithChildren<GroupProps>> = ({ spaced = true, children, className = '' }) => (
   <ButtonGroupContext.Provider value={{ spaced }}>
      <div className={`itm-btn-group ${className || ''}`}>{children}</div>
   </ButtonGroupContext.Provider>
)

export const getButtonColors = (bsStyle?: bsStyle) => {
   return {
      'itm-btn-bs--primary': bsStyle === 'primary',
      'itm-btn-bs--primary-outline': bsStyle === 'outline-primary',
      'itm-btn-bs--success': bsStyle === 'success',
      'itm-btn-bs--success-outline': bsStyle === 'outline-success',
      'itm-btn-bs--danger': bsStyle === 'danger',
      'itm-btn-bs--warning': bsStyle === 'warning',
      'itm-btn-bs--default': bsStyle === 'default',
      'itm-btn-bs--gray': bsStyle === 'gray',
      '': bsStyle === 'none',
   }
}

export const Button: FC<PropsWithChildren<ButtonProps>> & ButtonComposition = (props) => {
   const {
      children,
      onClick,
      className,
      buttonStyle,
      isLoading,
      showArrow,
      bsStyle = 'default',
      type = 'button',
      disabled = false,
      hasAnimation = true,
   } = props
   const { spaced } = useContext(ButtonGroupContext)
   let buttonClassName = classNames(className, 'itm-btn', {
      ...getButtonColors(bsStyle),
      'itm-btn--spaced': spaced,
      // 'itm-btn--simple': !spaced,
      'itm-btn--animated': hasAnimation,
      'itm-btn--withArrow': isLoading || showArrow,
   })

   return (
      <button
         {...(buttonStyle && { style: buttonStyle })}
         className={buttonClassName}
         type={type}
         {...(onClick && { onClick })}
         disabled={disabled}
      >
         {children}
         {showArrow && (
            <div className={classNames('itm-btn-arrow', { 'itm-btn-arrow--invisible': isLoading })}>
               <FontAwesomeIcon icon={faAngleRight} size="sm" />
            </div>
         )}
         {isLoading && (
            <div className="itm-btn-arrow--loading">
               <FontAwesomeIcon icon={faCircleNotch} size="sm" spin />
            </div>
         )}
      </button>
   )
}

export const CircleButton: FC<PropsWithChildren<ButtonProps>> = (props) => {
   const { children, className, onClick, bsStyle = 'default', type = 'button', disabled = false } = props
   let buttonClassName = classNames(className, 'itm-btn--circle', {
      ...getButtonColors(bsStyle),
   })
   return (
      <button type={type} className={buttonClassName} onClick={onClick} disabled={disabled}>
         {children}
      </button>
   )
}

Button.Group = Group
