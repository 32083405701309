import { useMutation, useQueryClient } from '@tanstack/react-query'
import { editOperation } from '../../api/operation'
import { QUERY_KEYS } from '../../constants/queryKeys'

const useUpdateOperation = () => {
   const queryClient = useQueryClient()
   return useMutation({
      mutationFn: editOperation,
      onSuccess: (response, request) => {
         queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_OPERATION, request.id] })
      }
   })

}
export { useUpdateOperation }
export default useUpdateOperation