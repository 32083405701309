import { useState, useCallback } from 'react'

export function useFileUpload(
   submitForm: Function,
   initialProgramId: number = 0,
   onSucces?: () => void,
   onError?: (e: any) => void,
) {
   const [showStatusBanner, setShowStatusBanner] = useState(false)
   const [programId, setProgramId] = useState(initialProgramId)
   const [file, setFile] = useState(null)

   const handleFileChange = useCallback(
      (file: File) => {
         setShowStatusBanner(true)
         if (file) {
            const formData = new FormData()
            formData.append('file', file)

            submitForm(
               {
                  loyaltyPlanId: programId,
                  file: file,
               },
               {
                  onSuccess: () => {
                     setShowStatusBanner(false)
                     setProgramId(initialProgramId)
                     setFile(null)
                     if (onSucces) {
                        onSucces()
                     }
                  },
                  onError: (e: any) => {
                     setShowStatusBanner(false)
                     if (onError) {
                        onError(e)
                     }
                  },
               },
            )
         }
      },
      [programId, submitForm, initialProgramId, onSucces, onError],
   )

   return {
      showStatusBanner,
      programId,
      setProgramId,
      file,
      setFile,
      handleFileChange,
   }
}

export default useFileUpload
