import { useIntl } from 'react-intl'
import Title from '../../common/Title'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGetPrograms } from '../../../hooks/useGetPrograms'
import { useAppSelector } from '../../../store/hooks'
import { FormOperation, FormOperationData } from './form/OperationForm'
import useGetPartner from '../../../hooks/useGetPartner'
import { toast } from 'react-toastify'
import { TextSpinner } from 'ui'
import useGetChannelList from '../../../hooks/useGetChannelList'
import useCreateOperation from '../../../hooks/useCreateOperation'

type Props = {}

const AddOperation = (props: Props) => {
   const intl = useIntl()
   const navigate = useNavigate()
   const { accountCode } = useParams()
   const base = useAppSelector((state) => state.base)
   const [searchParams] = useSearchParams()
   const partnerId = searchParams.get('partnerId')
   const { data: partner, isFetching: fetchingPartner } = useGetPartner(partnerId || '')
   const { data: channels, isLoading: loadingChannel } = useGetChannelList()
   const { data: programList, isLoading } = useGetPrograms()
   const { mutate, isPending } = useCreateOperation()

   const onSubmit = (data: FormOperationData) => {
      const { amount, roomsPerNight, numberOfNights, ...rest } = data
      mutate(
         {
            ...rest,
            memberId: partnerId!,
            currency: 'MXN',
            account: accountCode!,
            amount: Number(amount),
            roomsPerNight: Number(roomsPerNight),
            numberOfNights: Number(numberOfNights),
         },
         {
            onSuccess: (response) => {
               navigate(`/${accountCode}/partners/${partnerId}/operations`)
               toast.success(intl.formatMessage({ id: 'operations.create.success' }))
            },
            onError: (error: any) => {
               toast.error(
                  error?.response?.data?.error
                     ? error.response.data.error
                     : intl.formatMessage({ id: 'operations.create.error' }),
               )
            },
         },
      )
   }
   const onCancel = () => {
      navigate(`/${base.account?.code}/operations`)
   }
   if (isLoading || fetchingPartner || loadingChannel) {
      return (
         <div className="absolute inset-0 bg-neutral-400 opacity-25 flex items-center justify-center">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
         </div>
      )
   }
   return (
      <>
         <Title value={intl.formatMessage({ id: 'operations.create' })} />
         {partner ? (
            <div className="flex justify-between m-4">
               <div>
                  <h2 className="text-3xl mb-1">{`${partner.firstName} ${partner.lastName}`}</h2>

                  <div className="text-neutral-600">{partner.email}</div>
               </div>
               <div className="text-right">
                  <h2 className="text-3xl mb-1">{partner.externalId}</h2>
                  <div className="text-neutral-400">ID de socio</div>
               </div>
            </div>
         ) : (
            <div className="m-4">
               <h2 className="text-3xl mb-4">
                  <TextSpinner
                     style={{
                        width: '50%',
                        color: 'transparent',
                        lineHeight: '1.0667em',
                     }}
                  >
                     .
                  </TextSpinner>
               </h2>

               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
            </div>
         )}
         <FormOperation
            isLoading={isPending}
            programs={programList}
            channels={channels}
            onSubmit={onSubmit}
            onCancel={onCancel}
         />
      </>
   )
}

export default AddOperation
