import { useMemo, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Title from '../common/Title'
import Card from '../common/Card'
import { Amount, Button } from 'ui'
import { useIntl } from 'react-intl'
import { createDateFromISO } from 'utils/dates'
import qs from 'qs'
import { getCurrentValues, PartnerRow } from '../../utils/partnerUtils'
import Filters, { PartnerFilterForm } from './components/Filters'
import { plans } from '../../api/program'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { getPartnerExportList } from '../../api/partner'
import { useAppSelector } from '../../store/hooks'
import TablePartners from './components/TablePartners'
import { useGetProgramFilter } from '../../hooks/useGetProgramFilter'
import { toast } from 'react-toastify'
export type Level = {
   id: number
   name: string
}

export type Channel = {
   id: number
   name: string
}
type LoyaltyPlanHotel = {
   id: number
   hotelId: number
   hotelName: string
   discount: number
   contracts: number[]
}

type Photo = {
   id: number
   basePath: string
   path: string
   title: string
   galleryType: string
}

type Benefit = {
   id: number
   hotels: number[]
   name: string
}
export type LoyaltyPlan = {
   id: number
   name: string
   level: Level[]
   isActive: boolean
   hasDiscount: boolean
   primaryColor: string
   headerBackground: string
   headerFontColor: string
   hotels: LoyaltyPlanHotel[]
   photos: Photo[]
   benefits: Benefit[]
}

export type Partner = {
   id: number
   account: string
   createdAt: string
   hotelId: number
   firstName: string
   lastName: string
   email: string
   phoneNumber: string
   maritalStatus: 'MARRIED' | 'SINGLE' | 'DIVORCED' | 'WIDOWED' | 'SEPARATED'
   dateOfBirth: string
   gender: 'FEMALE' | 'MALE' | 'OTHER'
   countryOfResidence: string
   registrationChannel: string
   isActive: true
   externalId: number
   loyaltyPlans: Array<
      plans & {
         points: number
         level: string
         loyaltyPlanName: string
         expirationDate: string
         loyaltyPlanId: number
         registrationDate: string
         registrationChannel: string
      }
   >
   points: number
   totalRevenue: number
   latestReservationDate: string
   state?: string
   municipality?: string
   allergies?: string
   bloodType?: string
   swimmingSkill?: boolean
   passion?: string
   operaProfileSourceId?: string
   typeBlood?: string
   zipCode: string
   countryCode: string
   addressLine: string
}

type Props = {}

export const Partners = (props: Props) => {
   const intl = useIntl()
   const navigate = useNavigate()
   const location = useLocation()
   const { locale } = useAppSelector((state) => state.base)
   const [isExporting, setIsExporting] = useState(false)

   const { mutate } = useMutation({
      mutationFn: getPartnerExportList,
   })
   const { data: programs, isSuccess } = useGetProgramFilter()

   const columns = useMemo(
      () => [
         {
            header: intl.formatMessage({ id: 'partners.id' }),
            accessorKey: 'externalId',
            cell: ({ row }: { row: { original: PartnerRow } }) => {
               return <Link to={`${row.original.id}/operations`}>{row.original.externalId} </Link>
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'partners.table.partner' }),
            accessorKey: 'name',
            cell: ({ row }: { row: { original: PartnerRow } }) => {
               return (
                  <Link to={`${row.original.id}/operations`}>
                     {`${row.original.firstName} ${row.original.lastName}`}
                     <div className="text-sm text-neutral-400">{row.original.email}</div>
                  </Link>
               )
            },

            enableSorting: false,
         },
         {
            header: () => <div className="text-center">{intl.formatMessage({ id: 'partners.table.level' })}</div>,
            accessorKey: 'loyaltyPlans',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-center">{row.original.level}</div>
            ),
            enableSorting: false,
         },
         {
            header: () => (
               <div className="text-center">{intl.formatMessage({ id: 'partners.table.channelRegister' })}</div>
            ),
            accessorKey: 'registrationChannel',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-center">{row.original.registrationChannel || '--'}</div>
            ),
            enableSorting: false,
         },
         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'partners.points' })}</div>,
            accessorKey: 'points',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-right">
                  {row.original.totalRevenue ? (
                     <Amount
                        amount={row.original.points}
                        showCurrencySign={false}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                     />
                  ) : (
                     '--'
                  )}
               </div>
            ),
            enableSorting: true,
         },
         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'partners.income' })}</div>,
            accessorKey: 'totalRevenue',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-right">
                  {row.original.totalRevenue ? (
                     <Amount amount={row.original.totalRevenue} minimumFractionDigits={2} maximumFractionDigits={2} />
                  ) : (
                     '--'
                  )}
               </div>
            ),
            enableSorting: true,
         },
         {
            header: () => (
               <div className="text-center">{intl.formatMessage({ id: 'partners.table.room.Per.Nights' })}</div>
            ),
            accessorKey: 'nights',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-center">{row.original.roomNights}</div>
            ),
            enableSorting: true,
         },
         {
            header: <div className="text-center">{intl.formatMessage({ id: 'partners.lastOperation' })}</div>,
            accessorKey: 'lastOperation',
            cell: ({ row }: { row: { original: PartnerRow } }) => {
               const isoDate = createDateFromISO(row.original.lastOperationDate).setLocale(locale)
               return (
                  <div className="text-center">
                     {isoDate.isValid ? isoDate.toFormat(intl.formatMessage({ id: 'date.format' })) : '---'}
                     {isoDate.isValid ? (
                        <div className="text-sm text-neutral-400">{`${isoDate.toFormat('h:mm ')} hrs`}</div>
                     ) : (
                        ''
                     )}
                  </div>
               )
            },
            enableSorting: true,
         },
      ],
      [intl, locale],
   )
   const currentValues = getCurrentValues(location.search)
   const onFilter = (data: PartnerFilterForm) => {
      const request = Object.assign({}, data)
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }

   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onSort = (sort: () => Array<{ id: string; desc: boolean }>) => {
      const sortField = sort()?.[0]
      if (!sortField) return
      const { sort: sortFilter, ...currentValues } = getCurrentValues(location.search)
      const sort2 = sortFilter.includes('asc')
      const request = Object.assign(
         {},
         currentValues,
         !sort2
            ? {
                 sort: `${sortField.id},${sortField.desc ? 'desc' : 'asc'}`,
              }
            : null,
      )
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onExport = () => {
      const request = {
         ...getCurrentValues(location.search),
      }
      setIsExporting(true)
      mutate(request, {
         onSuccess: (response) => {
            let excellName = `members.xlsx`
            const blob = new Blob([response as unknown as BlobPart], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            setIsExporting(false)
         },
         onError: () => {
            setIsExporting(false)
            toast.error('Error al exportar los datos')
         },
      })
   }

   const onClickCreate = () => {
      navigate(`create`)
   }
   let defaultProgram = programs && programs.items ? programs.items.find((program) => program.isDefault)?.id : undefined
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'titles.partners' })}
         >
            <Button onClick={onClickCreate} className={'flex gap-x-2 items-center text-[12px] mr-4 '}>
               <FontAwesomeIcon icon={faUserPlus} className={'text-gray-500 text '} />
               {intl.formatMessage({ id: 'partner.create' })}
            </Button>
         </Title>
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               {isSuccess && programs.items && programs.items.length > 0 && (
                  <>
                     <Filters
                        className="px-4"
                        onSubmit={onFilter}
                        defaultValues={currentValues}
                        defaultProgram={defaultProgram}
                     />

                     <TablePartners
                        defaultProgram={defaultProgram}
                        currentValues={currentValues}
                        columns={columns}
                        onPageChange={onPageChange}
                        onSortChange={onSort}
                        onExport={onExport}
                        isExporting={isExporting}
                     />
                  </>
               )}
            </Card>
         </div>
      </>
   )
}
