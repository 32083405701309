import { plans } from '../../../../api/program'
import { useForm, Controller } from 'react-hook-form'
import Card from '../../../common/Card'
import { Input, Select } from 'ui/fields'
import { useIntl } from 'react-intl'
import DatePicker from 'react-datepicker'
import { Button } from 'ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { Channel } from '../../../partners'
import ServiceTypeField from './ServiceTypeFormField'

type Props = {
   programs?: {
      items: Array<plans>
      totalSize: number
   }
   channels?: {
      items: Array<Channel>
      totalSize: number
   }
   serviceTypes?: Array<{ id: number; description: string }>
   onSubmit: (data: FormOperationData) => void
   defautlValues?: any
   onCancel: () => void
   isLoading: boolean
   disabled?: boolean
   isCertificate?: boolean
}
export type FormOperationData = {
   loyaltyPlanId: number
   registationChannel: string
   operationType: 'Acumulación' | 'Redención'
   serviceType: number
   amount: number
   numberOfNights?: number
   roomsPerNight?: number
   invoiceNumber?: string
   reference?: string
   reservationDate: string
   operationDate: string
}
export const FormOperation = (props: Props) => {
   const {
      onSubmit,
      programs,
      defautlValues,
      onCancel,
      isLoading,
      channels,
      disabled = false,
      isCertificate = false,
   } = props
   const intl = useIntl()
   const {
      register,
      handleSubmit,
      control,
      formState: { errors, dirtyFields },
      setValue,
   } = useForm<FormOperationData>({
      mode: 'onChange',
      defaultValues: defautlValues ? defautlValues : {},
   })
   const getLabel = (field: string) => {
      return (
         <>
            {field}
            <FontAwesomeIcon icon={faCircle} size="xs" className={classNames('text-green-500 ml-1')} />
         </>
      ) as unknown as string
   }
   const formatOnchange = (value: string) => {
      return value ? Number(value) : value
   }

   return (
      <div className="flex flex-col gap-y-4 mx-4 ">
         <p className="pb-1 font-semibold">
            <FontAwesomeIcon icon={faCircle} size="xs" className={classNames('text-green-500 ml-1')} /> :{' '}
            {intl.formatMessage({ id: `partner.form.fields.required` })}
         </p>
         <Card className="w-full ">
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className="p-4  pb-1">
                  <Controller
                     name={`loyaltyPlanId`}
                     control={control}
                     rules={
                        disabled
                           ? undefined
                           : { required: intl.formatMessage({ id: 'operation.errors.loyaltyPlanId' }) }
                     }
                     render={({ field: { value, onChange } }) => (
                        <Select
                           label={getLabel(intl.formatMessage({ id: 'operation.form.loyaltyPlanId' }))}
                           value={value}
                           onChange={(i: string) => {
                              onChange(formatOnchange(i))
                           }}
                           error={errors?.loyaltyPlanId?.message}
                           showErrorText={true}
                           className="mb-4"
                           isTouched={dirtyFields.loyaltyPlanId}
                           disabled={disabled}
                        >
                           <Select.Option value="">--</Select.Option>
                           {programs?.items?.map((program) => (
                              <Select.Option key={program.id} value={program.id}>
                                 {program.name}
                              </Select.Option>
                           ))}
                        </Select>
                     )}
                  />
                  <Controller
                     name={`registationChannel`}
                     control={control}
                     rules={
                        disabled
                           ? undefined
                           : { required: intl.formatMessage({ id: 'operations.form.error.registationChannel' }) }
                     }
                     render={({ field: { value, onChange } }) => (
                        <Select
                           label={getLabel(intl.formatMessage({ id: 'operations.form.registationChannel' }))}
                           value={value}
                           onChange={(i: string) => onChange(formatOnchange(i))}
                           error={errors?.registationChannel?.message}
                           showErrorText={true}
                           className="mb-4"
                           isTouched={dirtyFields.registationChannel}
                           disabled={disabled}
                        >
                           <Select.Option value="">--</Select.Option>
                           {channels?.items.map((type) => (
                              <Select.Option key={type.id} value={type.id}>
                                 {type.name}
                              </Select.Option>
                           ))}
                        </Select>
                     )}
                  />
                  {isCertificate ? null : (
                     <>
                        <Controller
                           name={`operationType`}
                           control={control}
                           rules={
                              disabled
                                 ? undefined
                                 : { required: intl.formatMessage({ id: 'operation.errors.operationType' }) }
                           }
                           render={({ field: { value, onChange } }) => (
                              <Select
                                 label={getLabel(intl.formatMessage({ id: 'operation.form.operationType' }))}
                                 value={value}
                                 onChange={(i: string) => {
                                    onChange(i)
                                 }}
                                 error={errors?.operationType?.message}
                                 showErrorText={true}
                                 className="mb-4"
                                 isTouched={dirtyFields.operationType}
                                 disabled={disabled}
                              >
                                 <Select.Option value="">--</Select.Option>
                                 {['ACCUMULATION', 'REDEMPTION'].map((type) => (
                                    <Select.Option key={type} value={type}>
                                       {intl.formatMessage({ id: `operation.form.operationType.${type}` })}
                                    </Select.Option>
                                 ))}
                              </Select>
                           )}
                        />
                        <ServiceTypeField
                           disabled={disabled}
                           getLabel={getLabel}
                           control={control}
                           errors={errors}
                           dirtyFields={dirtyFields}
                           intl={intl}
                           formatOnchange={formatOnchange}
                           setValue={setValue}
                           isEdit={disabled}
                        />
                     </>
                  )}

                  <Input
                     type="number"
                     label={getLabel(intl.formatMessage({ id: `operation.form.amount` }))}
                     {...register('amount', {
                        required: disabled ? undefined : intl.formatMessage({ id: 'operation.errors.amount' }),
                     })}
                     error={errors?.amount?.message}
                     isTouched={dirtyFields.amount}
                     step="0.01"
                     min={0}
                     containerClassName="mb-4"
                     disabled={disabled}
                  />
                  {isCertificate ? null : (
                     <>
                        {' '}
                        <Input
                           type="number"
                           label={intl.formatMessage({ id: `operation.form.numberOfNights` })}
                           {...register('numberOfNights')}
                           error={errors?.numberOfNights?.message}
                           isTouched={dirtyFields.numberOfNights}
                           containerClassName="mb-4"
                           min={0}
                           disabled={disabled}
                        />
                        <Input
                           type="number"
                           label={intl.formatMessage({ id: `operation.form.rooms` })}
                           {...register('roomsPerNight')}
                           error={errors?.roomsPerNight?.message}
                           isTouched={dirtyFields.roomsPerNight}
                           containerClassName="mb-4"
                           min={0}
                           disabled={disabled}
                        />
                     </>
                  )}
                  <Input
                     type="text"
                     label={intl.formatMessage({ id: `operation.form.invoice` })}
                     {...register('invoiceNumber', {
                        ...(disabled && {
                           required: 'Requerido',
                        }),
                     })}
                     error={errors?.invoiceNumber?.message}
                     isTouched={dirtyFields.invoiceNumber}
                     containerClassName="mb-4"
                  />
                  <div>
                     <label htmlFor="reference" className="block font-medium">
                        {intl.formatMessage({ id: `operation.form.reference` })}
                     </label>
                     <textarea
                        id="reference"
                        {...register('reference', {
                           maxLength: {
                              value: 800,
                              message: intl.formatMessage({ id: 'operation.errors.reference.caracters' }),
                           },

                           ...(disabled && {
                              required: 'Requerido',
                           }),
                        })}
                        className={`border ${
                           errors.reference ? 'border-red-500' : 'border-gray-300'
                        } rounded-md w-full p-2`}
                        rows={5}
                     />
                     {errors.reference && <p className="text-red-500 text-sm mt-1">{errors.reference.message}</p>}
                  </div>
                  {isCertificate ? null : (
                     <Controller
                        name="reservationDate"
                        control={control}
                        rules={
                           disabled ? undefined : { required: intl.formatMessage({ id: 'operation.errors.stayDate' }) }
                        }
                        render={({ field }) => (
                           <div className={`my-4 ${errors?.reservationDate?.message ? 'has-error' : ''}`}>
                              <label className="block mb-2 font-semibold">
                                 {getLabel(intl.formatMessage({ id: `operation.form.stayDate` }))}
                              </label>
                              <DatePicker
                                 placeholderText="Seleccionar fecha"
                                 closeOnScroll
                                 onChange={(date) => field.onChange(date)}
                                 onBlur={field.onBlur}
                                 selected={field.value as unknown as Date}
                                 dateFormat="dd/MM/yyyy"
                                 showIcon
                                 isClearable
                                 maxDate={new Date()}
                                 disabled={disabled}
                                 className={`w-full border border-gray-300 rounded-md p-2 ${
                                    errors?.reservationDate?.message ? 'form-control' : ''
                                 }`}
                              />
                              {errors?.reservationDate?.message && (
                                 <div className="text-red-500 text-[12px] mt-1">{errors?.reservationDate?.message}</div>
                              )}
                           </div>
                        )}
                     />
                  )}
                  <Controller
                     name="operationDate"
                     control={control}
                     rules={
                        disabled
                           ? undefined
                           : { required: intl.formatMessage({ id: 'operation.errors.operationDate' }) }
                     }
                     render={({ field }) => (
                        <div className={`my-4 ${errors?.operationDate?.message ? 'has-error' : ''}`}>
                           <label className="block mb-2 font-semibold">
                              {getLabel(intl.formatMessage({ id: `operation.form.operationDate` }))}
                           </label>
                           <DatePicker
                              placeholderText="Seleccionar fecha"
                              closeOnScroll
                              onChange={(date) => field.onChange(date)}
                              onBlur={field.onBlur}
                              selected={field.value as unknown as Date}
                              dateFormat="dd/MM/yyyy"
                              showIcon
                              isClearable
                              maxDate={new Date()}
                              disabled={disabled}
                              className={`w-full border border-gray-300 rounded-md p-2 ${
                                 errors?.operationDate?.message ? 'form-control' : ''
                              }`}
                           />
                           {errors?.operationDate?.message && (
                              <div className="text-red-500 text-[12px] mt-1">{errors?.operationDate?.message}</div>
                           )}
                        </div>
                     )}
                  />
               </div>
               <div className="border mt-3 "></div>
               <div className="flex justify-start gap-x-4 p-5">
                  <Button
                     type="submit"
                     bsStyle="default"
                     buttonStyle={{
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        color: 'white',
                        backgroundColor: 'rgb(217 119 6 / var(--tw-bg-opacity))',
                     }}
                     disabled={isLoading}
                  >
                     {isCertificate
                        ? intl.formatMessage({ id: 'operations.form.button.certificate.desactivate' })
                        : 'Aceptar'}
                  </Button>
                  <Button bsStyle="default" onClick={onCancel}>
                     Cancelar
                  </Button>
               </div>
            </form>
         </Card>
      </div>
   )
}
