import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Operation } from '../components/operations'
import { getOperationList, GetOperationListRequest } from '../api/operation'

export type OperationListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<Operation>
   totalSize: number
}

const initialState: OperationListState = {
   isPristine: true,
   isFetching: false,
   items: [],
   totalSize: 0,
}

export const getOperationListThunk = createAsyncThunk(
   'getOperationList',
   async (request: GetOperationListRequest, { rejectWithValue }) => {
      try {
         return await getOperationList(request)
      } catch {
         return rejectWithValue(null)
      }
   },
)

export const operationListSlice = createSlice({
   name: 'operationListSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getOperationListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getOperationListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload.items
         state.totalSize = payload.totalSize
      })
      builder.addCase(getOperationListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = operationListSlice.actions

export default operationListSlice.reducer
