import { useState } from 'react'
import Title from '../../common/Title'
import { useIntl } from 'react-intl'
import Card from '../../common/Card'
import FileUploadButton from '../../common/FileUploadButton'
import { Select } from 'ui/fields'
import { useGetProgramFilter } from '../../../hooks/useGetProgramFilter'
import { Button } from 'ui'
import { faFileArrowDown, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import { getOperationLayoutPMS, importFilePMSOperations } from '../../../api/operation'
import useIsProcessingImport from '../../../hooks/useIsProcessingImport/useIsProcessingImport'
import Alert from 'ui/Alert'
import { getAlertType } from '../../../utils/partnerUtils'
import { createDateFromISO } from 'utils/dates'
import useGetInstructionTableOperationPMS from '../../../hooks/useGetInstructionTableOperationPMS'
import { toast } from 'react-toastify'
import classNames from 'classnames'

function ImportOperation() {
   const { data: programs, isLoading } = useGetProgramFilter()
   const { data: dataImported } = useIsProcessingImport({ type: 'OPERATIONS_PMS' })

   const intl = useIntl()
   const [error, setError] = useState(null)
   const [file, setFile] = useState(null)
   const [isExporting, setIsExporting] = useState(false)

   const [isLoadingImport, setIsLoadingImport] = useState(false)
   const [programId, setProgramId] = useState('')
   const [showInfo, setShowInfo] = useState(false)
   const { mutate: submitForm } = useMutation({
      mutationFn: importFilePMSOperations,
   })
   const { mutate: getLayout } = useMutation({
      mutationFn: getOperationLayoutPMS,
   })
   const { data: table, isLoading: tableInstruction } = useGetInstructionTableOperationPMS(programId)
   const onSubmit = () => {
      if (!programId || !file) {
         return
      }
      setIsLoadingImport(true)
      submitForm(
         {
            loyaltyPlanId: Number(programId),
            file: file,
         },
         {
            onSuccess: () => {
               setIsLoadingImport(false)
               setProgramId('')
               setFile(null)
            },
            onError: (e: any) => {
               setIsLoadingImport(false)
               setError(e.message)
               setFile(null)
            },
         },
      )
   }
   const handleLayoutButton = () => {
      getLayout(
         {},
         {
            onSuccess: (response) => {
               const blob = new Blob([response], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
               })
               const url = window.URL.createObjectURL(blob)

               const link = document.createElement('a')
               link.href = url
               link.download = 'import_pms_layout.xlsx'
               document.body.appendChild(link)
               link.click()
               document.body.removeChild(link)
               window.URL.revokeObjectURL(url)
               link.remove()
               setIsExporting(false)
               toast.success(intl.formatMessage({ id: 'Archivo descargado correctamente' }))
            },
            onError: (e: any) => {
               setIsExporting(false)
               toast.error('Error al exportar los datos')
            },
         },
      )
   }
   if (dataImported && dataImported.result) {
      return (
         <div className="px-5 py-2">
            <Card className="p-5">
               <Alert className={'m-5'} type={getAlertType(dataImported.result.status)}>
                  <Alert.Body className="font-semibold">
                     <div>{dataImported?.message}</div>
                     <div>
                        {intl.formatMessage(
                           { id: 'partner.createAt' },
                           {
                              date: createDateFromISO(dataImported.result.createdAt)
                                 .setLocale(intl.locale)
                                 .toFormat("cccc d 'de' LLLL 'del' yyyy"),
                           },
                        )}
                     </div>
                  </Alert.Body>
               </Alert>
            </Card>
         </div>
      )
   }
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'operations.import' })}
         ></Title>
         <div className="px-5 py-2">
            <Card className="p-5">
               <FileUploadButton
                  loading={isLoadingImport}
                  disabled={isLoadingImport || isLoading || tableInstruction}
                  file={file}
                  setFile={setFile}
                  error={error}
                  setError={setError}
                  helpText={intl.formatMessage({ id: 'operations.importHelpText' })}
                  fileTypeList={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                  loadingLabel="Cargando operaciones..."
               />
               <div className="border border-t-0 border-gray-300 hover:border-gray-400 flex items-end justify-between">
                  <div className="p-4">
                     <Select
                        label={intl.formatMessage({ id: 'program.planName' })}
                        value={programId}
                        onChange={setProgramId}
                        showErrorText={true}
                        disabled={isLoadingImport || isLoading || tableInstruction}
                     >
                        <Select.Option value="">--</Select.Option>
                        {programs?.items.map((program) => (
                           <Select.Option key={program.id} value={program.id}>
                              {program.name}
                           </Select.Option>
                        ))}
                     </Select>
                  </div>
                  <div className="p-4">
                     <Button
                        onClick={onSubmit}
                        disabled={!programId || !file || isLoadingImport || isLoading}
                        buttonStyle={{ paddingLeft: '35px', paddingRight: '35px' }}
                        bsStyle="primary"
                     >
                        Continuar
                     </Button>
                  </div>
               </div>
               <div className="mt-10">
                  <div className="flex justify-between mb-7">
                     <div className="flex items-center ">
                        <button
                           className={classNames('font-semibold cursor-pointer hover:underline', {
                              'text-gray-500':
                                 isLoadingImport || isLoading || isExporting || !programId || tableInstruction,
                           })}
                           onClick={() => setShowInfo(!showInfo)}
                           disabled={isLoadingImport || isLoading || isExporting || !programId || tableInstruction}
                        >
                           Mostrar instrucciones de llenado
                        </button>
                        {showInfo ? (
                           <FontAwesomeIcon
                              icon={faSortUp}
                              className={classNames('ml-1 text-gray-500', {
                                 'text-gray-500':
                                    isLoadingImport || isLoading || isExporting || !programId || tableInstruction,
                              })}
                           />
                        ) : (
                           <FontAwesomeIcon
                              icon={faSortDown}
                              className={classNames('ml-1 text-gray-500', {
                                 'text-gray-500':
                                    isLoadingImport || isLoading || isExporting || !programId || tableInstruction,
                              })}
                           />
                        )}
                     </div>
                     {showInfo && (
                        <Button
                           onClick={handleLayoutButton}
                           disabled={isLoadingImport || isLoading || isExporting || !programId || tableInstruction}
                           buttonStyle={{ paddingLeft: '35px', paddingRight: '35px' }}
                           bsStyle="outline-primary"
                        >
                           <FontAwesomeIcon icon={faFileArrowDown} className="mr-1 text-gray-500" />
                           Descargar archivos de ejemplo
                        </Button>
                     )}
                  </div>
                  {showInfo && programId && (
                     <div className="overflow-x-auto">
                        <table className="w-full text-sm border">
                           <thead className="bg-gray-50">
                              <tr>
                                 <th className="px-4 py-3 border text-left font-medium text-gray-600">DATO</th>
                                 <th className="px-4 py-3 border text-left font-medium text-gray-600">COLUMNA</th>
                                 <th className="px-4 py-3 border text-left font-medium text-gray-600">TIPO DE DATO</th>
                                 <th className="px-4 py-3 border text-left font-medium text-gray-600">OPCIONES</th>
                              </tr>
                           </thead>
                           <tbody className="divide-y divide-gray-200">
                              {table?.map((row, index) => (
                                 <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                                    <td className="px-4 py-3">{row.name}</td>
                                    <td className="px-4 py-3">{row.column}</td>
                                    <td className="px-4 py-3">{row.dataType}</td>
                                    <td className="px-4 py-3">{row.values}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  )}
               </div>
            </Card>
         </div>
      </>
   )
}

export default ImportOperation
