import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { Dropdown } from 'ui'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useGetProgramFilter } from '../../../hooks/useGetProgramFilter'
import classNames from 'classnames'

export type PartnerOperationsFilterForm = {
   loyaltyPlanIds: number | string
}
type Props = {
   defaultValues?: PartnerOperationsFilterForm
   defaultProgram?: number
   onSubmit: (data: PartnerOperationsFilterForm) => void
   children?: React.ReactNode
   className?: string
}

const PartnerOperationsFilters = ({ defaultValues, defaultProgram, onSubmit, children, className }: Props) => {
   const intl = useIntl()
   const formButton = useRef<HTMLFormElement>(null)
   const { data: programs } = useGetProgramFilter()
   const { handleSubmit, control } = useForm<PartnerOperationsFilterForm>({
      defaultValues: {
         loyaltyPlanIds: defaultValues?.loyaltyPlanIds || defaultProgram,
      },
      mode: 'onChange',
   })
   let programId = useWatch({ control, name: 'loyaltyPlanIds' })
   const planLoyalty = programs?.items.find((program) => {
      return typeof programId === 'string' ? program.id === parseInt(programId) : program.id === programId
   })?.name

   function onSubmitEvent(data: PartnerOperationsFilterForm) {
      console.log(data)
      onSubmit(data)
   }

   return (
      <form onSubmit={handleSubmit(onSubmitEvent)} ref={formButton} className={classNames(className)}>
         <div>
            <div className="text-sm">{intl.formatMessage({ id: 'partners.program' })}</div>
            <Dropdown onClose={handleSubmit(onSubmitEvent)} className="inline-block">
               <Dropdown.Toggle className="cursor-pointer flex items-center justify-between font-semibold text-gray-500 whitespace-nowrap">
                  {planLoyalty}
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <ul>
                     <Controller
                        control={control}
                        name="loyaltyPlanIds"
                        render={({ field: { ref, onChange, ...rest } }) => (
                           <>
                              {programs?.items?.map((option) => {
                                 if (!option.hasPointBenefit) {
                                    return null
                                 }
                                 return (
                                    <li
                                       key={option.id}
                                       className="hover:bg-gray-100 focus:outline-none cursor-pointer block  whitespace-nowrap font-light p-2"
                                       onClick={() => {
                                          onChange(option.id)
                                          formButton.current?.click()
                                       }}
                                    >
                                       {option.name}
                                    </li>
                                 )
                              })}
                           </>
                        )}
                     />
                  </ul>
               </Dropdown.Menu>
            </Dropdown>
         </div>
         {children}
      </form>
   )
}

export default PartnerOperationsFilters
