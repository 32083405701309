import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createOperation } from '../../api/operation'
import { QUERY_KEYS } from '../../constants/queryKeys'

const useCreateOperation = () => {
   const queryClient = useQueryClient()
   return useMutation({
      mutationFn: createOperation,
      onSuccess: (response, request) => {
         queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_OPERATION, response.id] })
      }
   })
}

export { useCreateOperation }
export default useCreateOperation