import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import { TextSpinner } from 'ui'
import Card from '../../common/Card'
import { useGetPartnerHistory } from '../../../hooks/useGetPartnerHistory'

export type HistoryItem = {
   pointsAccumulated: number
   pointsRedeemed: number
   hotelName: string
   service: string
   id: string
}

type Props = {
   idPlan: number | undefined
}

const History = (props: Props) => {
   const { idPlan } = props
   const intl = useIntl()
   const { accountCode, id } = useParams()
   const { data: history, status } = useGetPartnerHistory({ memberId: id, size: 10, page: 0 })
   return (
      <Card>
         <Card.Title>
            <div className="flex justify-between aling-center">{intl.formatMessage({ id: 'partner.history' })}</div>
         </Card.Title>
         {status === 'pending' ? (
            Array.from({ length: 4 }, (_, i) => (
               <div className="p-4" key={i}>
                  <div className="flex gap-2">
                     <FontAwesomeIcon icon={faCircle} size="xs" className={classNames('mt-1.5 text-neutral-400')} />
                     <div className="w-full">
                        <TextSpinner className="mb-2" />
                        <TextSpinner />
                     </div>
                  </div>
               </div>
            ))
         ) : (
            <>
               <div
                  className={classNames('overflow-auto', {
                     'border-neutral-200': history?.items.length === 0,
                     'h-[545px]': history && history?.items.length > 15,
                  })}
               >
                  {history?.items.map((item, index) => (
                     <div className="p-4" key={index}>
                        <div className="flex gap-2">
                           <FontAwesomeIcon
                              icon={faCircle}
                              size="xs"
                              className={classNames('mt-1.5', {
                                 'text-green-500': item.pointsAccumulated > 0,
                                 'text-blue-500': item.pointsRedeemed > 0,
                              })}
                           />
                           <Link to={`/${accountCode}/operations?query=${item.id}`}>
                              <FormattedMessage
                                 id="partner.historyItem"
                                 values={{
                                    name: item.hotelName,
                                    service: item.service,
                                    points:
                                       item.pointsAccumulated > 0 ? (
                                          <strong>
                                             {intl.formatMessage(
                                                { id: 'partner.points.accumulated' },
                                                { points: item.pointsAccumulated },
                                             )}
                                          </strong>
                                       ) : (
                                          <strong>
                                             {intl.formatMessage(
                                                { id: 'partner.points.redeemed' },
                                                { points: item.pointsRedeemed },
                                             )}
                                          </strong>
                                       ),
                                 }}
                              />
                           </Link>
                        </div>
                     </div>
                  ))}
               </div>
               <div className="p-4 border-t">
                  <Link className="text-blue-500" to={`/${accountCode}/operations?query=${idPlan || id}`}>
                     {intl.formatMessage({ id: 'partner.all.operations' })}
                  </Link>
               </div>
            </>
         )}
      </Card>
   )
}

export default History
