import classNames from 'classnames'
import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from 'ui'
import { faCheckCircle, faTimesCircle, faUndo } from '@fortawesome/free-solid-svg-icons'
type FileUploadButtonProps = {
   loading: boolean
   file: File | null
   setFile: Function
   handleFileChange?: (file: File) => void
   error: string | null
   setError: Function
   helpText: string
   fileTypeList?: string[]
   className?: string
   disabled?: boolean
   loadingLabel?: string
}
function FileUploadButton({
   loading,
   file,
   setFile,
   handleFileChange,
   error,
   setError,
   helpText,
   fileTypeList = ['text/csv'],
   className,
   disabled,
   loadingLabel = 'Cargando...',
}: FileUploadButtonProps) {
   const inputFile = useRef<HTMLInputElement | null>(null)
   const handleOnDragOver = (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault()
   }
   const validateFile = (file: File) => {
      if (!file) {
         setError('No se ha seleccionado ningún archivo')
         return false
      }
      console.log(file.type)
      if (file.size > 10 * 1024 * 1024) {
         setError('El archivo debe ser menor a 10MB')
         return false
      }

      if (fileTypeList.indexOf(file.type) < 0) {
         setError('El archivo no tiene el formato correcto. Selecciona otro archivo.')
         return false
      }
      setError(null)
      return true
   }
   const handleClickButton = () => {
      if (inputFile.current) {
         inputFile?.current.click()
      }
   }
   const handleOnDrop = (event: React.DragEvent<HTMLElement>) => {
      setError(null)
      if (loading || disabled) return null
      event.preventDefault()
      const file = event.dataTransfer.files[0]
      const errorFile = validateFile(file)
      if (errorFile) {
         setFile(file)
         if (handleFileChange) {
            handleFileChange(file)
         }
      } else {
         setFile(null)
      }
   }
   const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setError(null)
      if (event.target.files) {
         const file = event.target.files[0]
         const errorFile = validateFile(file)
         if (errorFile) {
            setFile(file)
            if (handleFileChange) {
               handleFileChange(file)
            }
         } else {
            setFile(null)
         }
      }
   }
   const mimeToExtension: Record<string, string> = {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-excel': 'xls', // Si soportas .xls
      'text/csv': 'csv', // Soporte para CSV
   }
   const formattedFileTypes = fileTypeList
      .map((f: string) => mimeToExtension[f] || f.toUpperCase().split('/')[1])
      .join(', ')
   return (
      <section
         onDragOver={handleOnDragOver}
         onDrop={handleOnDrop}
         className={classNames(
            'p-10 border-2 border-solid border-gray-300  text-center',
            {
               'hover:border-gray-400': !loading || !disabled,
               'cursor-not-allowed': disabled,
            },
            className,
         )}
      >
         <div className={'text-center flex flex-col items-center'}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ccc" width={45} viewBox="0 0 384 512">
               <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
            </svg>
            <p className=" max-w-[424px] mx-auto mt-4 mb-3">{helpText}</p>
            <Button bsStyle="primary" disabled={loading || disabled} onClick={handleClickButton}>
               Seleccionar archivo
            </Button>
            {file && (
               <p className="mt-4">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-green-500" />
                  Archivo seleccionado: <strong>{file.name}</strong>
               </p>
            )}
            {loading && (
               <p className="mt-4">
                  <FontAwesomeIcon icon={faUndo} className="mr-2 text-gray-500" />
                  {loadingLabel}
               </p>
            )}
            {error && typeof error === 'string' ? (
               <p className="text-red-500 mt-4">
                  <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-red-500" />
                  {error}
               </p>
            ) : (
               <p>{error}</p>
            )}
            <p className="text-muted mt-4">Formatos soportados: {formattedFileTypes}. Peso máximo: 10MB</p>
            {error && <p className="text-red-500 mt-4">{error}</p>}
         </div>
         <input type="file" className="opacity-0" id="uploadRateFile" ref={inputFile} onChange={handleOnChange} />
      </section>
   )
}

export default FileUploadButton
