import React from 'react'
import { Partner } from '..'
import classNames from 'classnames'
import { Button, TextSpinner } from 'ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../store/hooks'
import { Link, useNavigate } from 'react-router-dom'
import { createDateFromISO } from 'utils/dates'

type Codes = 'operations' | 'info'

type Props = {
   partner?: Partner
   partnerStatus: string
   accountCode?: string
   id?: string
   children?: React.ReactNode
   activeTabCode: Codes
}

const PartnerTemplate = ({ partner, partnerStatus, accountCode, id, children, activeTabCode }: Props) => {
   const intl = useIntl()
   const { locale } = useAppSelector((state) => state.base)
   const navigate = useNavigate()

   function drawTabs(items: Array<{ code: Codes; title: string; url: string }> = [], active: number = 0) {
      return (
         <div className="m-4 pl-4 border-b border-gray-300 flex justify-between items-center">
            <ul className="flex flex-wrap text-center text-gray-500">
               {items.map((item, index) => (
                  <li key={item.title} className="me-2">
                     <Link
                        to={`/${accountCode}/partners/${id}${item.url}`}
                        aria-current="page"
                        className={classNames('inline-block px-4 py-2 rounded-t border border-gray-300', {
                           'border-b-gray-100 -mb-1': item.code === activeTabCode,
                           'border-b-0 bg-gray-50 hover:text-gray-600 hover:bg-gray-50': item.code !== activeTabCode,
                        })}
                     >
                        {item.title}
                     </Link>
                  </li>
               ))}
            </ul>
            {partner && accountCode && id && (
               <Button
                  onClick={() => {
                     navigate(`/${accountCode}/operations/create?partnerId=${id}`)
                  }}
                  bsStyle="none"
                  className="flex gap-x-2 items-center -mt-2 bg-amber-500 text-white border-amber-500 hover:bg-amber-500/90"
               >
                  <FontAwesomeIcon icon={faPlus} className={'text-white'} />
                  {intl.formatMessage({ id: 'operations.new' })}
               </Button>
            )}
         </div>
      )
   }

   return (
      <>
         {partnerStatus === 'pending' ? (
            <div className="absolute inset-0 bg-neutral-400 opacity-25 flex items-center justify-center">
               <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>
         ) : null}
         {partner ? (
            <div className="flex justify-between m-4">
               <div>
                  <h2 className="text-3xl mb-1">{`${partner.firstName} ${partner.lastName}`}</h2>

                  <div className="text-neutral-400">
                     {partner.createdAt &&
                        intl.formatMessage(
                           { id: 'partner.from' },
                           {
                              date: createDateFromISO(partner.createdAt)
                                 .setLocale(locale)
                                 .toFormat('EEEE, dd MMMM yyyy'),
                           },
                        )}
                  </div>
               </div>
               <div className="text-right">
                  <h2 className="text-3xl mb-1">{partner.externalId}</h2>
                  <div className="text-neutral-400">ID de socio</div>
               </div>
            </div>
         ) : (
            <div className="m-4">
               <h2 className="text-3xl mb-4">
                  <TextSpinner
                     style={{
                        width: '50%',
                        color: 'transparent',
                        lineHeight: '1.0667em',
                     }}
                  >
                     .
                  </TextSpinner>
               </h2>

               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
            </div>
         )}
         {drawTabs(
            [
               { code: 'operations', title: 'Operaciones', url: '/operations' },
               { code: 'info', title: 'Información de socio', url: '' },
            ],
            0,
         )}
         {children}
      </>
   )
}

export default PartnerTemplate
