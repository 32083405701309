import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getOperation } from '../../api/operation'

function useGetOperation(id?: string) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_OPERATION, id],
      queryFn: () => {
         return getOperation(id)
      },
      enabled: !!id,
   })
}
export { useGetOperation }
export default useGetOperation
